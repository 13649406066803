import { memo } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./CheckBox.module.scss";

export const CheckBox = ({
  name,
  handleChange,
  value,
  checkedValue,
  id = null,
  className = null,
  label = "",
  disabled = false,
  isSizeMedium = false,
  isSizeSmall = false,
  isShapeCheckIcon = false,
  isError = false,
  children,
}) => {
  /**
   * Toggle checkbox value
   */
  const handleOnChange = () => {
    handleChange(name, value !== checkedValue ? checkedValue : null);
  };

  let classNames = isSizeMedium
    ? `${styles.formCheckbox} ${styles.medium}`
    : isSizeSmall
      ? `${styles.formCheckbox} ${styles.small}`
      : isError
        ? `${styles.formCheckbox} ${styles.error}`
        : styles.formCheckbox;

  if (isShapeCheckIcon) {
    classNames += ` ${styles.iconShape}`;
  }

  return (
    <div className={cn(classNames, className)}>
      <input
        type="checkbox"
        id={id ?? `${name}Id`}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleOnChange}
        checked={checkedValue === value}
      />

      <label htmlFor={id ?? `${name}Id`}>
        {label && <span>{label}</span>}
        {children}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  checkedValue: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isShapeCheckIcon: PropTypes.bool,
  isError: PropTypes.any,
};

export default memo(CheckBox);
